import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedPipe } from '../_pipes/shared.pipe';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { TabLinksComponent } from './components/tab-links/tab-links.component';
import { FooterNavbarComponent } from './components/footer-navbar/footer-navbar.component';

@NgModule({
	declarations: [HeaderComponent, FooterComponent, TabLinksComponent, FooterNavbarComponent],
	imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, SharedPipe],
	exports: [HeaderComponent, FooterComponent, TabLinksComponent,FooterNavbarComponent],
})
export class SharedModule {}
