import { Injectable } from '@angular/core';
import { ServerResponse } from '../_models/server-response';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class HomeService {
    constructor(private apiService: ApiService) {
    }

    async getGenres(): Promise<ServerResponse> {
        return await this.apiService.get('Website/get-genres').toPromise();
    }

    async getStates(): Promise<ServerResponse> {
        return await this.apiService.get('Website/get-states').toPromise();
    }

    async getArtist(genre, state): Promise<ServerResponse> {
        return await this.apiService.get(`Website/get-artists?page=1&count=10&cachebust=${Math.random()}&genre=${genre}&state=${state}`).toPromise();
    }

    async notify(payload): Promise<ServerResponse> {
        return await this.apiService.postForm(`Website/notify-list`, payload).toPromise();
    }

   async getTicker(): Promise<ServerResponse> {
        return await this.apiService.get('Home/ticker').toPromise();
    }
}
