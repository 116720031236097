import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	showPopup = false;
	iframeURL = 'https://m.gigmor.com/tabs/home';
	messageListener:any;
	iFrameCustomWidth:boolean=false;
	constructor() {}

	ngOnInit(): void {
		this.messageListener =(event:MessageEvent)=>{
           if(event.data.type=='ROUTE_CHANGE'){
			this.adjustIframeWidth(event.data.route)
		   }
		}
		window.addEventListener('message',this.messageListener,false)
	}
	
	adjustIframeWidth(currentRouteOftheIFrame:string){
		console.log("adjustIframeWidth",currentRouteOftheIFrame);
		
		if( currentRouteOftheIFrame.includes('/show-tickets-detail') ){
            this.iFrameCustomWidth=true;
		}else{
		    this.iFrameCustomWidth=false;
		}
	}
	openPage(page) {
		switch (page) {
			case 'artist':
				this.iframeURL = 'https://m.gigmor.com/tabs/explore?tab=artist';
				break;
			case 'industry':
				this.iframeURL = 'https://m.gigmor.com/tabs/explore?tab=industry';
				break;
			case 'shows':
				this.iframeURL = 'https://m.gigmor.com/tabs/shows';
				break;
			case 'gigs':
				this.iframeURL = 'https://m.gigmor.com/tabs/gigs';
				break;
			case 'fans':
				this.iframeURL = 'https://m.gigmor.com/tabs/home';
				break;
			case 'faq':
				this.iframeURL = 'https://m.gigmor.com/faqs';
				break;
			case 'terms':
				this.iframeURL = 'https://m.gigmor.com/terms';
				break;
			case 'conduct':
				this.iframeURL = 'https://m.gigmor.com/rules';
				break;
			case 'privacy':
				this.iframeURL = 'https://m.gigmor.com/privacy';
				break;
			default:
				break;
		}
		this.showPopup = true;
	}
}
