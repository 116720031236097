import { Component, OnInit } from '@angular/core';
import { HomeService } from '../../_services/home.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  device = '';
  showSuccess = false;
  constructor(
    private homeService: HomeService,
    private deviceService: DeviceDetectorService,
  ) { }

  ngOnInit(): void {
    this.device = this.deviceService.device;
  }

  registerForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
    device: new FormControl(''),
  });

  async saveNotification() {
    let payload = {
      email: this.registerF.email.value,
      device: this.device,
    };
    let temp = await this.homeService.notify(payload);
    this.showSuccess = true;
    this.registerForm.reset();
    window.setTimeout(() => {
      this.showSuccess = false;
    }, 5000);
  }

  get registerF(): any { return this.registerForm.controls; }

}
