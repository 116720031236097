<nav class="navbar navbar-expand-lg eerieBlackBg rounded">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleNavbar()">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse eerieBlackBg" id="navbarNavDropdown">
        <ul class="navbar-nav justify-content-between w-100 eerieBlackBg text-white h-75">
            <li class="nav-item flex-grow-1">
                <a class="nav-link text-center text-white rounded navLinkContent" routerLink="/about" routerLinkActive="active">About</a>
            </li>
            <li class="nav-item flex-grow-1">
                <a class="nav-link text-center text-white rounded navLinkContent" routerLink="/faqs" routerLinkActive="active">FAQs</a>
            </li>
            <li class="nav-item flex-grow-1">
                <a class="nav-link text-center text-white rounded navLinkContent" target="_blank" href="https://blog.gigmor.com/" >Blog</a>
            </li>
            <li class="nav-item flex-grow-1">
                <a class="nav-link text-center text-white rounded navLinkContent" routerLink="/contact" routerLinkActive="active">Contact</a>
            </li>
        </ul>
    </div>
</nav>
