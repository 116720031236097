import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-navbar',
  templateUrl: './footer-navbar.component.html',
  styleUrls: ['./footer-navbar.component.scss']
})
export class FooterNavbarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  toggleNavbar() {
    var navbarCollapse = document.getElementById('navbarNavDropdown');
    navbarCollapse.classList.toggle('show');
  }
}
