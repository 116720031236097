import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-tab-links',
	templateUrl: './tab-links.component.html',
	styleUrls: ['./tab-links.component.scss'],
})
export class TabLinksComponent implements OnInit {
	@Input() active: string = '';
	constructor() {}

	ngOnInit(): void {}
}
