import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './landing/landing.component';

const routes: Routes = [
	{ path: 'mobile', component: LandingComponent },
	{ path: 'home', loadChildren: () => import('./home/home.module').then((m) => m.HomeModule) },
	{ path: '', loadChildren: () => import('./home/home.module').then((m) => m.HomeModule) },
	{ path: 'about', loadChildren: () => import('./about/about.module').then((m) => m.AboutModule) },
	{ path: 'contact', loadChildren: () => import('./contact/contact.module').then((m) => m.ContactModule) },
	{ path: 'terms', loadChildren: () => import('./terms/terms.module').then((m) => m.TermsModule) },
	{ path: 'privacy', loadChildren: () => import('./privacy/privacy.module').then((m) => m.PrivacyModule) },
	{ path: 'faqs', loadChildren: () => import('./faqs/faqs.module').then((m) => m.FaqsModule) },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
