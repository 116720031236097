<!--------- Header area start --------->
<section class="hero__area" style="background-image: url(assets/img/hero_bg.jpg)">
	<div class="container">
		<div class="row">
			<div class="col-lg-12">
				<div class="header__main__blk">
					<div class="header__first__blk">
						<div class="header__logo">
							<a href="index.html"><img src="assets/img/logo_white.svg" alt="" /></a>
						</div>
						<div class="header__menu">
							<ul>
								<li><a (click)="openPage('artist')">Artists</a></li>
								<li><a (click)="openPage('industry')">Industry</a></li>
								<li><a (click)="openPage('shows')">Shows</a></li>
								<li><a (click)="openPage('gigs')">Gigs</a></li>
							</ul>
						</div>
					</div>
					<div class="header__btn__blk">
						<a target="_blank" href="https://apps.apple.com/us/app/gigmor/id1639391211">Download App</a>
						<a href="#">Log in</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--------- Header area end --------->

<div [hidden]="!showPopup">
	<div class="overlay"></div>
	<div class="center-popup">
		<div [ngClass]="{ 'ios': true, 'phone-device': !this.iFrameCustomWidth, 'custom-phone-device': iFrameCustomWidth }" >
			<!-- <img src="../../assets/img/Close.png" (click)="showPopup = false" class="close-icon" /> -->
			<figure style="margin: 0 auto"><iframe scrolling="no" importance="high" [src]="iframeURL | safe : 'resourceUrl'"></iframe></figure>
		</div>
	</div>
</div>
