<!--------- Footer area start --------->
<div class="footer__area">
	<div class="container">
		<div class="row">
			<div class="col-lg-4 col-md-5">
				<div class="footer__first__blk">
					<div class="footer__logo">
						<a href="index.html"><img src="assets/img/logo.svg" alt="" /></a>
					</div>
					<div class="footer__menu">
						<ul>
							<li><a routerLink="/about">About</a></li>
							<li><a routerLink="/faqs" >FAQs</a></li>
							<li><a target="_blank" href="https://blog.gigmor.com/">Blog</a></li>
							<li><a routerLink="/contact">Contact</a></li>
						    	<!-- <li><a routerLink="/terms">Terms</a></li>
						    	<li><a routerLink="/privacy">Privacy</a></li> 
							    <li><a (click)="openPage('faq')" href="#">FAQ</a></li> 
								<li><a (click)="openPage('terms')" href="#">Terms</a></li>
								<li><a (click)="openPage('conduct')" href="#">Conduct</a></li>
								<li><a (click)="openPage('privacy')" href="#">Privacy</a></li> -->
						</ul>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-md-1">
				<div class="footer__app__blk hide-tablet">
					<div class="footer__app__title">
						<h4>Get the App!</h4>
					</div>
					<div class="footr__apps">
						<a target="_blank" href="https://apps.apple.com/us/app/gigmor/id1639391211"><img src="assets/img/app_store.png" alt="" /></a>
						<a target="_blank" href="https://play.google.com/store/apps/details?id=com.gigmor"><img src="assets/img/google_play.png" alt="" /></a>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-md-5">
				<div class="footer__social__blk">
					<div class="footer__social__title">
						<h4>Follow us and stay connected</h4>
					</div>
					<div class="footer__socials">
						<a target="_blank" href="https://instagram.com/gigmor/"><img src="assets/icons/instagram-2.svg" alt="" /></a>
						<a target="_blank" href="https://www.facebook.com/gigmor"><img src="assets/icons/facebook.svg" alt="" /></a>
						<!-- <a target="_social" href="https://www.twitter.com/gigmor"><img src="assets/img/p3.svg" alt="" /></a> -->
						<a target="_blank" href="https://www.tiktok.com/@gigmorofficial"><img src="assets/icons/tiktok-2.svg" alt="" /></a>
						<a target="_blank" href="https://www.youtube.com/c/Gigmor"><img src="assets/icons/youtube-2.svg" alt="" /></a>
						<a target="_blank" href="https://open.spotify.com/user/h7wrmzkc0buxojnssqajz5ymh?si=dc9424ad6c144c83"
							><img src="assets/icons/spotify-3.svg" alt=""
						/></a>
					</div>
				</div>
			</div>
		</div>
		<div class="row mt-5 hide-tablet">
			<div class="col-lg-12 footer__app__copyright text-center">Gigmor ©2023 All Rights Reserved.</div>
		</div>

		<div class="show-tablet">
			<div class="row mt-5">
				<div class="col-lg-4"></div>
				<div class="col-lg-4">
					<div class="footer__app__blk">
						<div class="footer__app__title">
							<h4>Get the App!</h4>
						</div>
						<div class="footr__apps">
							<a target="_blank" href="https://apps.apple.com/us/app/gigmor/id1639391211"><img src="assets/img/app_store.png" alt="" /></a>
							<a target="_blank" href="https://play.google.com/store/apps/details?id=com.gigmor"><img src="assets/img/google_play.png" alt="" /></a>
						</div>
					</div>
				</div>
				<div class="col-lg-4"></div>
			</div>
			<div class="row mt-5">
				<div class="col-lg-12 footer__app__copyright text-center">Gigmor ©2021 All Rights Reserved.</div>
			</div>
		</div>
	</div>
</div>
<!--------- Footer area end --------->
