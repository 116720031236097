<div class="background-wrapper">
    <div class="landing-wrapper">
        <div class="container">
            <div class="row" style="margin: 0px;">
                <div class="col-0 col-md-2 col-lg-2">
                </div>
                <div class="col-12 col-md-8 col-lg-8 ">
                    <div class="logo text-center">
                        <img class="img-responsive" src="../../assets/img/landing-logo.png" />
                    </div>
                </div>
                <div class="col-0 col-md-2 col-lg-2">
                </div>
                <div class="col-12 col-md-8 col-lg-8  newsletter">
                    <h1>Get Notified When Our App Launches </h1>
                    <p>As soon as our new Gigmor App is ready to download in the App Stores, you’ll be the first to know!</p>
                    <form [formGroup]="registerForm" (ngSubmit)="!registerF.email.errors &&  saveNotification()"
                        class="form-validate">
                        <div>
                            <div class="input-group mb-3">
                                <input type="email" class="form-control" required placeholder="Enter Email "
                                    formControlName="email"
                                    [ngClass]="{ 'is-invalid': (registerF.email.errors?.required || registerF.email.errors?.email || registerF.email.errors?.pattern) }">

                                <div class="input-group-append">
                                    <button class="btn btn-dark" type="submit">Notify me</button>
                                </div>
                            </div>
                        </div>
                        <p *ngIf="showSuccess" style="font-size: 12px; margin: 0px; padding: 0px;">Thank you. Your
                            subscription was successful.</p>
                    </form>

                </div>
                <div class="col-4"></div>
            </div>
            <div class="footer">
                <div class="row">
                    <div class="col-6" style="padding: 0px;"> <a href="https://artifact.news/tos.pdf" class="float-right" style="float: right;" target="_blank"><span style="margin-right: 10px;"> Terms of Service </span> </a>
                    </div>
                    <div class="col-6" style="padding: 0px;"> <a href="https://artifact.news/privacy.pdf" class="float-left"  style="float: left;" target="_blank">•<span style="margin-left: 10px;"> Privacy Policy </span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>