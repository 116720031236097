import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { LandingComponent } from './landing/landing.component';

@NgModule({
	declarations: [AppComponent, LandingComponent],
	imports: [
		BrowserModule,
		HttpClientModule,
		AppRoutingModule,
		CommonModule,
		FormsModule,
		CarouselModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		SharedModule,
	],
	providers: [HttpClientModule],
	bootstrap: [AppComponent],
})
export class AppModule {}
